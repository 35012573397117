import React from 'react';
import tw from 'twin.macro';
import { css } from "styled-components/macro"; //eslint-disable-line

import { useTranslation, Link as TranslationLink } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import LogoImage from '../../images/logo.svg';

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5 mb-5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw(TranslationLink)`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;
const ClassicLink = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 mt-1 text-xl text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const Wrapper = styled.div`
  position: relative;
  padding: 0 2rem 0 2rem;
`;

export default () => {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <Container>
                <FiveColumns>
                    <WideColumn>
                        <LogoContainer>
                            <LogoImg src={LogoImage} alt="Logo" />
                            <LogoText style={{ fontFamily: 'Josefin Sans', fontWeight: '700' }}>LUNAAR</LogoText>
                        </LogoContainer>
                        <CompanyDescription>
                            <strong>{t('footer.company.a')}</strong>
                            <br />
                            <br />
                            {t('footer.company.b')}
                        </CompanyDescription>
                    </WideColumn>
                    <Column>
                        <ColumnHeading>{t('footer.cards.1.heading')}</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <ClassicLink href="https://center.lunaar.app/">{t('footer.cards.1.list.1')}</ClassicLink>
                            </LinkListItem>
                            <LinkListItem>
                                <Link to="/">{t('footer.cards.1.list.2')}</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link to="/pricing">{t('footer.cards.1.list.3')}</Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    <Column>
                        <ColumnHeading>{t('footer.cards.2.heading')}</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <Link to="/about">{t('footer.cards.2.list.1')}</Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    <Column>
                        <ColumnHeading>{t('footer.cards.3.heading')}</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <Link to="/privacy">{t('footer.cards.3.list.1')}</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link to="/terms">{t('footer.cards.3.list.2')}</Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                </FiveColumns>
            </Container>
        </Wrapper>
    );
};
