import React from 'react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from "styled-components/macro"; //eslint-disable-line

import menuIcon from 'feather-icons/dist/icons/menu.svg';
import closeIcon from 'feather-icons/dist/icons/x.svg';
import { useTranslation, Link as TranslationLink } from 'gatsby-plugin-react-i18next';
import useAnimatedNavToggler from '../../helpers/useAnimatedNavToggler';

import logo from '../../images/logo_light.svg';

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`relative inline-block z-10`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw(TranslationLink)`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 text-black
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const ClassicNavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 text-black
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(ClassicNavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-hover hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const Link = tw(TranslationLink)`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 text-black
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent
`;

export const LogoLink = styled(Link)`
  ${tw`relative z-10 flex items-center border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion.custom(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({
    roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = 'lg',
}) => {
    const { t } = useTranslation();

    const defaultLinks = [
        <NavLinks key={1}>
            <NavLink to="/">{t('header.features')}</NavLink>
            <NavLink to="/pricing">{t('header.pricing')}</NavLink>
            <NavLink to="/about">{t('header.about')}</NavLink>
            <PrimaryLink tw="lg:ml-12!" css={roundedHeaderButton && tw`rounded-full`} href="https://center.lunaar.app/">{t('header.app')}</PrimaryLink>
        </NavLinks>,
    ];

    const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
    const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

    const defaultLogoLink = (
        <LogoLink to="/">
            <img src={logo} alt="logo" />
            <div style={{
                fontFamily: 'Josefin Sans', fontWeight: '700', letterSpacing: '0px', marginTop: '5px',
            }}
            >
                LUNAAR
            </div>
        </LogoLink>
    );

    logoLink = logoLink || defaultLogoLink;
    links = links || defaultLinks;

    return (
        <Header className={className || 'header-light'}>
            <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
                {logoLink}
                {links}
            </DesktopNavLinks>
            <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
                {logoLink}
                <MobileNavLinks initial={{ x: '150%', display: 'none' }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
                    {links}
                </MobileNavLinks>
                <NavToggle onClick={toggleNavbar} className={showNavLinks ? 'open' : 'closed'} aria-label="Toggle Navbar">
                    {showNavLinks ? <img src={closeIcon} tw="w-6 h-6" alt="" /> : <img src={menuIcon} tw="w-6 h-6" alt="" />}
                </NavToggle>
            </MobileNavLinksContainer>
        </Header>
    );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
    sm: {
        mobileNavLinks: tw`sm:hidden`,
        desktopNavLinks: tw`sm:flex`,
        mobileNavLinksContainer: tw`sm:hidden`,
    },
    md: {
        mobileNavLinks: tw`md:hidden`,
        desktopNavLinks: tw`md:flex`,
        mobileNavLinksContainer: tw`md:hidden`,
    },
    lg: {
        mobileNavLinks: tw`lg:hidden`,
        desktopNavLinks: tw`lg:flex`,
        mobileNavLinksContainer: tw`lg:hidden`,
    },
    xl: {
        mobileNavLinks: tw`lg:hidden`,
        desktopNavLinks: tw`lg:flex`,
        mobileNavLinksContainer: tw`lg:hidden`,
    },
};
